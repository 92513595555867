import { useCallback, useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Collapse,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { BsDownload, BsFillLightningChargeFill, BsGearFill, BsStars } from 'react-icons/bs';
import { FiThumbsUp, FiThumbsDown } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';

import { commonTextFieldProps } from 'theme';
import { DATAFLOW_AI } from '../constants';
import toast from 'react-hot-toast';
import { nanoid } from 'nanoid';
import { IoMdThumbsDown, IoMdThumbsUp } from 'react-icons/io';
import { usePopulateQueryBuilder } from 'pages/Connection';

const useStyles = makeStyles({
  primaryBtn: {
    backgroundColor: '#354a5f',
    '&:hover': {
      backgroundColor: '#121a21',
    },
  },
  secondaryBtn: {
    color: '#354a5f',
    borderColor: '#354a5f',
  },
  adjustDownloadButton: {
    marginRight: '24px',
    color: '#0a6ed1',
  },
  nudgeButtons: {
    marginRight: '16px',
  },
  positionLikeDislikeButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
  },
  IconBtn: {
    color: '#354a5f',
  },
});

export default function AIQueryButton({ baseEndpoint, schema, systemType }) {
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        startIcon={<BsStars />}
        className={classes.primaryBtn}
        disableElevation
      >
        Query with AI
      </Button>
      {isDialogOpen && (
        <AIQueryForm
          handleCloseDialog={handleCloseDialog}
          isDialogOpen={isDialogOpen}
          baseEndpoint={baseEndpoint}
          schema={schema}
          systemType={systemType}
        />
      )}
    </div>
  );
}

const initialTrainingInfoState = {
  naturalLanguageQuery: '',
  generatedQuery: '',
  isAccurate: null,
  revisedQuery: '',
};
function AIQueryForm({ handleCloseDialog, isDialogOpen, baseEndpoint, schema, systemType }) {
  const classes = useStyles();

  const [dialogKey, setDialogKey] = useState(() => nanoid());
  const [userInput, setUserInput] = useState('');
  const [revisedInput, setRevisedInput] = useState('');
  const [generatedQuery, setGeneratedQuery] = useState('');
  const [trainingInfo, setTrainingInfo] = useState(initialTrainingInfoState);
  const [isLoading, setIsLoading] = useState(false);

  const { populateQueryBuilder } = usePopulateQueryBuilder({
    baseEndpoint,
    schema,
    systemType,
  });

  // Flushing the dialog by assigning it a new key when the dialog is closed
  useEffect(() => {
    setDialogKey(nanoid());
    return () => setDialogKey(nanoid());
  }, []);

  const handleUserInput = useCallback(({ currentTarget }) => {
    setUserInput(currentTarget.value);
  }, []);

  const handleRevisedInput = useCallback(({ currentTarget }) => {
    setRevisedInput(currentTarget.value);
  }, []);

  const handleGenerate = useCallback(async () => {
    setIsLoading(true);
    const response = await axios
      .post('http://localhost:5000/nl_to_odata', {
        user_input: userInput,
        base_url: baseEndpoint,
      })
      .then((response) => response?.data)
      .catch(() => toast.error('Failed to generate OData query'))
      .finally(() => setIsLoading(false));

    if (response.result.startsWith(baseEndpoint)) {
      setGeneratedQuery(response.result);
      // We set the isAccurate to true by default, the user can change it otherwise
      setTrainingInfo((state) => ({
        ...state,
        naturalLanguageQuery: userInput,
        generatedQuery: response.result,
        isAccurate: true,
      }));
    } else {
      setGeneratedQuery('');
      toast.error('Failed to generate OData query');
    }
  }, [baseEndpoint, userInput]);

  const handleThumbsUp = useCallback(() => {
    setTrainingInfo((state) => ({ ...state, isAccurate: true }));
  }, []);

  const handleThumbsDown = useCallback(() => {
    setTrainingInfo((state) => ({ ...state, isAccurate: false }));
    setRevisedInput(generatedQuery);
  }, [generatedQuery]);

  const handleRunQuery = useCallback(() => {
    const isQueryAccurate = trainingInfo.isAccurate;

    if (!isQueryAccurate) {
      setTrainingInfo((state) => ({ ...state, revisedQuery: revisedInput }));
    }

    const queryUrl = isQueryAccurate ? generatedQuery : revisedInput;
    populateQueryBuilder(queryUrl, true);

    const trainingLogs = localStorage.getItem(DATAFLOW_AI);
    const parsedLogs = JSON.parse(trainingLogs);
    const updatedLogs = [...(parsedLogs ?? []), { ...trainingInfo, revisedQuery: revisedInput }];

    localStorage.setItem(DATAFLOW_AI, JSON.stringify(updatedLogs));

    handleCloseDialog();
  }, [generatedQuery, handleCloseDialog, populateQueryBuilder, revisedInput, trainingInfo]);

  const handleDownloadLogs = () => {
    const trainingLogs = localStorage.getItem(DATAFLOW_AI);
    const parsedLogs = JSON.parse(trainingLogs);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.columns = columns;

    parsedLogs.forEach((item) => {
      worksheet.addRow([
        item.naturalLanguageQuery,
        item.generatedQuery,
        item.isAccurate,
        item.revisedQuery,
      ]);
    });

    // Create a buffer from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Create a Blob object from the buffer
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Save the blob as a file using FileSaver.js
      saveAs(blob, `${DATAFLOW_AI}_TRAINING_LOGS.xlsx`);
    });
  };

  const isRunQueryDisabled = !trainingInfo.isAccurate && !Boolean(revisedInput);

  return (
    isDialogOpen && (
      <Dialog
        key={dialogKey}
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <DialogTitle id="form-dialog-title">Query with AI</DialogTitle>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={handleDownloadLogs}
              startIcon={<BsDownload />}
              size="small"
              className={classes.adjustDownloadButton}
              variant="outlined"
            >
              Download Logs
            </Button>
          </Grid>
        </Grid>
        <DialogContent>
          <DialogContentText>
            Enter your natural language query below, and watch as it's seamlessly converted into an
            OData query, simplifying data retrieval and enhancing your user experience.
          </DialogContentText>
          <TextField
            autoFocus
            label="Your Query"
            {...commonTextFieldProps}
            variant="outlined"
            fullWidth
            placeholder="eg: get me a list of employees who belong to company 7000"
            value={userInput}
            onChange={handleUserInput}
          />
        </DialogContent>
        <DialogActions className={classes.nudgeButtons}>
          {!trainingInfo.generatedQuery && (
            <Button
              onClick={handleCloseDialog}
              color="primary"
              variant="outlined"
              size="small"
              className={classes.secondaryBtn}
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={handleGenerate}
            color="primary"
            variant="contained"
            startIcon={isLoading ? <CircularProgress size={18} /> : <BsGearFill fontSize={12} />}
            className={classes.primaryBtn}
            size="small"
            disableElevation
            disabled={isLoading}
          >
            {isLoading ? 'Generating' : 'Generate'}
          </Button>
        </DialogActions>
        <Collapse in={Boolean(generatedQuery)} timeout="auto" unmountOnExit>
          <DialogContent>
            <TextField
              id="standard-required"
              label="AI generated query"
              value={generatedQuery}
              {...commonTextFieldProps}
              fullWidth
              variant="outlined"
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
            <DialogActions className={classes.positionLikeDislikeButtons}>
              <IconButton
                color="primary"
                size="small"
                className={classes.IconBtn}
                onClick={handleThumbsUp}
              >
                {trainingInfo.isAccurate ? <IoMdThumbsUp fontSize={18} /> : <FiThumbsUp />}
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                onClick={handleThumbsDown}
                className={classes.IconBtn}
              >
                {trainingInfo.isAccurate ? <FiThumbsDown /> : <IoMdThumbsDown fontSize={18} />}
              </IconButton>
            </DialogActions>
          </DialogContent>
          <Collapse in={!trainingInfo.isAccurate} timeout="auto" unmountOnExit>
            <DialogContent>
              <TextField
                id="standard-required"
                label="Correct query"
                placeholder="Enter correct query"
                {...commonTextFieldProps}
                fullWidth
                variant="outlined"
                multiline
                value={revisedInput}
                onChange={handleRevisedInput}
              />
            </DialogContent>
          </Collapse>
          <DialogActions className={classes.nudgeButtons}>
            {trainingInfo.generatedQuery && (
              <Button
                onClick={handleCloseDialog}
                color="primary"
                variant="outlined"
                size="small"
                className={classes.secondaryBtn}
              >
                Cancel
              </Button>
            )}
            <Button
              onClick={handleRunQuery}
              color="primary"
              variant="contained"
              className={classes.primaryBtn}
              disabled={isRunQueryDisabled}
              size="small"
              disableElevation
              startIcon={<BsFillLightningChargeFill size={14} />}
            >
              Run Query
            </Button>
          </DialogActions>
        </Collapse>
      </Dialog>
    )
  );
}

const columns = [
  { header: 'Natural Language Query', key: 'naturalLanguageQuery' },
  { header: 'AI Generated Query', key: 'generatedQuery' },
  { header: 'Is Accurate', key: 'isAccurate' },
  { header: 'Revised Query', key: 'revisedQuery' },
];
