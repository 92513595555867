/**
 * @file
 *
 * This file contains the base component for the `/connection` route
 */
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {
  Grid,
  makeStyles,
  IconButton,
  Button,
  Typography,
  Collapse,
  Fab,
  withStyles,
  Tooltip,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  useParams,
  Link as RouterLink,
  useRouteMatch,
  Switch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import * as go from 'gojs';
import { useAsyncFn, useUpdateEffect } from 'react-use';
import {
  MdChevronRight,
  MdError,
  MdExpandMore,
  MdFileDownload,
  MdGridOn,
  MdSettings,
} from 'react-icons/md';
import { IoIosArrowBack } from 'react-icons/io';
import { RiBarChartBoxLine } from 'react-icons/ri';
import { BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs';
import queryString from 'query-string';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';
import { omit, isEmpty } from 'lodash-es';
import toast from 'react-hot-toast';
import { common } from '@material-ui/core/colors';
import * as Sentry from '@sentry/react';
import sortArray from 'sort-array';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ScopedCssBaseline,
  ThemeProvider,
} from '@mui/material';
import { CgBolt } from 'react-icons/cg';
import { BsArchive } from 'react-icons/bs';
import { BiTable } from 'react-icons/bi';

import { getServiceInstance, dataflowApiBase, getHelpPageLink } from '../service';
import { QueryBuilder } from '../components/QueryBuilder';
import { DataTable } from '../components/DataTable';
import { Nav } from '../components/Nav';
import { useODataConnection, CONNECTION_ERROR_TYPE } from '../data/odataConnection';
import {
  traversePaginatedQueryRequests,
  flattenRecords,
  getOriginalEntityType,
  formQueryURL,
} from '../odata/utils';
import { ConnectionLoader } from '../components/Loaders/ConnectionPageLoader';
import { SidePanel, drawerWidth } from '../components/SidePanel';
import { useSidePanel } from '../hooks/useSidePanel';
import { SchemaVisualizer } from '../components/SchemaVisualizer';
import { ErrorImage } from '../components/Illustrations/Error';
import {
  useInitializeQueryBuilderState,
  QueryUrlProvider,
  useQueryBuilderState,
  pickQueryParamsFromQueryBuilderState,
} from '../state/queryBuilder';
import { QueryDisplay } from '../components/QueryDisplay';
import { parseQueryUrlAndPopulateQueryBuilder } from '../odata/queryStringParser';
import { useLoggedInUser, useTenantState, useUser } from '../data/user';
import { CreateBookmarkDialog, useCreateBookmarkDialog } from '../components/CreateBookmarkDialog';
import {
  useInitializeSchemaVisualizerState,
  useSchemaVisualizerState,
} from '../state/schemaVisualizer';
import {
  bulkExportStateActions,
  useBulkExportState,
  BULK_EXPORT_STAGES,
  calculateDownloadProgress,
  usePreventNavigationDuringExport,
} from '../components/BulkExportDialog';
import { tourStateActions, tourSteps, useTourState } from '../components/Onboarding/Tour';
import { wrapErrorMessageWithFallback } from '../hooks/useNotifyError';
import { CONFIG } from '../config';
import { ExpiryBanner } from '../components/ExpiryBanner';
import { useInitialLoadNormal } from '../hooks/useInitialLoadChecks';
import {
  API_ERROR_TYPES,
  CONNECTION_TYPES,
  EMAIL_FORMATS,
  HELP_PAGES,
  PING_TEST,
} from '../constants';
import { FEATURE_CODES, useBillingUsage } from '../data/billingUsage';
import {
  getBillingExpiryStatus,
  getMailToString,
  goBackToPreviousPage,
  navigateToSystems,
} from 'utils';
import { formatEdmDateTime } from 'date';
import { createQuery, translateVariablesToDateTimeString } from 'odata/queryBuilder';
import {
  useConnection,
  useConnectionHelper,
  useConnectionsAndSystems,
} from 'data/connectionsAndSystems';
import { reportViewStateHandlers } from 'state/reportView';
import { useDisclosure } from 'hooks/useDisclosure';
import { mui5Theme } from 'mui5Theme';
import { ReportView } from 'components/ReportView';
import { AccessDeniedImage } from 'components/Illustrations/AccessDenied';
import ArchivedConnectionImage from 'components/Illustrations/ArchivedConnectionImage';
import PageNotFound from 'components/Illustrations/PageNotFound';
import AIQueryButton from 'components/AIQueryButton';

const useFetchQueryData = (connectionId, entityTypesMap) => {
  const tenant = useTenantState();
  const queryClient = useQueryClient();

  const [queryData, fetchQueryData] = useAsyncFn(
    async (url, selectedEntity, metadata) => {
      const response = await getServiceInstance(tenant?.tenant_id)
        .post(
          `${dataflowApiBase}/connection/${connectionId}/proxy`,
          { url: `${url}&$format=json&$inlinecount=allpages` },
          { responseType: 'json' }
        )
        .finally(() => {
          queryClient.invalidateQueries([`/connection/${connectionId}/log`, tenant?.tenant_id]);
        });

      const selectedColumns = queryString.parseUrl(url)?.query?.$select?.split(',') ?? [];

      // check if the column is selected
      const columns = selectedColumns.length
        ? // this is because the complex types are flattened out in the selected entity and we just need to check for the base property
          selectedEntity.property.filter(({ name }) => selectedColumns.includes(name.split('/')[0]))
        : // everything is selected if there are no specific selected columns
          selectedEntity.property;

      // columns is a frozen object and hence we cannot mutate it, so we are duplicating the array and sorting
      // the duplicate array (columnsSnapshot)
      const columnsSnapshot = [...columns];
      sortArray(columnsSnapshot, {
        by: 'isKey',
        order: 'isKey',
        customOrders: {
          isKey: [true, false],
        },
      });

      let rawRecords = [];
      if (Array.isArray(response.d)) {
        rawRecords = response.d;
      } else {
        rawRecords = await traversePaginatedQueryRequests(
          response.d,
          rawRecords,
          connectionId,
          tenant?.tenant_id
        );
      }

      const inlineCount = response?.d?.__count ?? 0;

      const baseEntityType = getOriginalEntityType(selectedEntity, entityTypesMap);
      const { records } = flattenRecords(rawRecords, baseEntityType, metadata);

      return { records, columns: columnsSnapshot, key: nanoid(), inlineCount };
    },
    [connectionId, entityTypesMap]
  );

  return { queryData, fetchQueryData };
};

const useQueryBuilderExpand = (queryData) => {
  const [isQueryBuilderExpanded, updateExpandState] = useState(true);

  const toggleQueryBuilderExpand = useCallback(
    (flag) => {
      updateExpandState((state) => {
        if (typeof flag === 'boolean') {
          return flag;
        }

        return !state;
      });
    },
    [updateExpandState]
  );

  useUpdateEffect(() => {
    if (queryData.loading) {
      updateExpandState(false);
    }
  }, [queryData.loading]);

  return { isQueryBuilderExpanded, toggleQueryBuilderExpand };
};

export const usePopulateQueryBuilder = ({
  baseEndpoint,
  schema,
  systemType,
  toggleDrawer = () => {},
  toggleQueryBuilderExpand = () => {},
}) => {
  const importAdhocQuery = useCallback(
    (queryUrl) => {
      const newStateSlice = parseQueryUrlAndPopulateQueryBuilder({
        queryUrl,
        baseEndpoint,
        schema,
        systemType,
      });

      useQueryBuilderState.set((state) => ({
        ...state,
        ...omit(newStateSlice, 'entity'),
        entity: {
          options: state.entity.options,
          value: newStateSlice.entity,
        },
      }));

      toggleQueryBuilderExpand(true);
    },
    [baseEndpoint, schema, systemType, toggleQueryBuilderExpand]
  );

  const populateQueryBuilder = useCallback(
    (queryUrl, skipDrawerToggle) => {
      try {
        importAdhocQuery(queryUrl);
        if (!skipDrawerToggle) {
          toggleDrawer();
        }
      } catch (error) {
        toast.error(wrapErrorMessageWithFallback(error, 'Failed to parse the query'));
      }
    },
    [toggleDrawer, importAdhocQuery]
  );

  return { populateQueryBuilder, importAdhocQuery };
};

const useStyles = makeStyles((theme) => ({
  titleBar: {
    height: theme.spacing(6),
    top: theme.spacing(5.5),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(0.5, 2.5, 0),
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    borderBottom: theme.borders[0],
  },
  backButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(2),
  },
  floatingActionButton: {
    position: 'fixed',
    zIndex: theme.zIndex.modal - 1,
    boxShadow: theme.shadows[0],
    left: theme.spacing(1),
    top: theme.spacing(12.5),
  },
  panelOpen: {
    marginLeft: theme.spacing(drawerWidth),
  },
  container: {
    height: '100%',
    margin: theme.spacing(10, 0, 0),
    paddingTop: theme.spacing(4),
  },
  formContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  sidePanel: {
    height: '100%',
    width: theme.spacing(drawerWidth),
  },
  queryExpand: {
    border: theme.borders[3],
    marginRight: theme.spacing(1),
    padding: 0,
    marginTop: theme.spacing(-0.5),
  },
  visualizeBtn: {
    marginRight: theme.spacing(1),
  },
  entityTagCollectionLabel: {
    marginRight: theme.spacing(1.75),
  },
  expandPanel: {
    padding: theme.spacing(1.25, 0),
  },
  unsupportedFiltersContainer: {
    maxHeight: theme.spacing(30),
    backgroundColor: 'rgb(255, 244, 229)',
    color: 'rgb(102, 60, 0)',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
  unsupportedFilter: {
    marginTop: theme.spacing(0.5),
  },
  abortBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export const CustomFab = withStyles((theme) => ({
  root: {
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))(Fab);

const generateExportImage = (diagramInstance) => {
  if (diagramInstance.current) {
    /**
     * @type {go.Diagram}
     */
    const diagram = diagramInstance.current;

    const { bottom, right } = diagram.documentBounds;

    const oldSkipSettings = diagram.skipsUndoManager;
    diagram.skipsUndoManager = true;

    const addWatermarkTxnIdentifier = 'add watermark';

    diagram.startTransaction(addWatermarkTxnIdentifier);

    const key = 'INTEGRTR-watermark';
    diagram.model.addNodeData({
      category: 'watermarkNode',
      location: go.Point.stringify(new go.Point(right - 135 * 0.5, bottom + 40)),
      key: key,
    });

    diagram.commitTransaction(addWatermarkTxnIdentifier);

    const img = diagram.makeImageData({
      type: 'image/png',
      background: common.white,
      scale: 1,
      maxSize: new go.Size(Infinity, Infinity),
      padding: new go.Margin(100, 20, 20, 100),
    });

    const removeWatermarkTxnIdentifier = 'remove watermark';
    diagram.startTransaction(removeWatermarkTxnIdentifier);

    diagram.model.removeNodeData(diagram.findNodeForKey(key).data);

    diagram.commitTransaction(removeWatermarkTxnIdentifier);

    diagram.skipsUndoManager = oldSkipSettings;

    return img;
  }

  return null;
};

const useExportImage = (connectionName) => {
  const diagramInstance = useRef(null);

  const updateDiagramInstance = useCallback((diagram) => {
    diagramInstance.current = diagram;
  }, []);

  const handleExport = useCallback(() => {
    const img = generateExportImage(diagramInstance);

    if (img) {
      const a = document.createElement('a');
      a.href = img;
      a.download = `${connectionName}.png`;
      a.click();
    } else {
      toast.error('Failed to export the diagram');
    }
  }, [diagramInstance, connectionName]);

  return { handleExport, updateDiagramInstance };
};

function ConnectionPage({
  metadata,
  baseEndpoint,
  baseParams,
  title,
  isMetadataLoading,
  isMetadataParsing,
  isSchemaVisualizerOpen,
  toggleSchemaVisualizer,
  updateDiagramInstance,
  error,
  showAbortExportDialog,
  setShowAbortExportDialog,
}) {
  const classes = useStyles();

  const { connectionId } = useTourState();

  const params = useParams();
  const { selectedConnection: connection } = useConnection(params.connectionId);
  const systemType = connection?.user_system?.system;

  const { isDrawerOpen, toggleDrawer } = useSidePanel(false);
  const { bookmarkDialog, openCreateBookmarkDialog, closeCreateBookmarkDialog } =
    useCreateBookmarkDialog();

  const { queryData, fetchQueryData } = useFetchQueryData(
    params.connectionId,
    metadata?.entityTypesMap
  );
  const { isQueryBuilderExpanded, toggleQueryBuilderExpand } = useQueryBuilderExpand(queryData);
  const { populateQueryBuilder, importAdhocQuery } = usePopulateQueryBuilder({
    baseEndpoint,
    schema: metadata,
    systemType,
    toggleDrawer,
    toggleQueryBuilderExpand,
  });
  const { data: billingUsage } = useBillingUsage();
  const handleError = useErrorHandler();
  useInitializeQueryBuilderState(metadata, populateQueryBuilder);
  useInitializeSchemaVisualizerState(metadata);
  usePreventNavigationDuringExport();

  const closeAbortExportDialog = useCallback(() => {
    setShowAbortExportDialog({ showDialog: false, navigateTo: null });
  }, [setShowAbortExportDialog]);

  const fetchQueriedData = useCallback(
    (event, triggerTour = true) => {
      event.preventDefault();

      const queryBuilderState = useQueryBuilderState.get();

      const queryString = createQuery({
        schema: metadata,
        systemType,
        ...pickQueryParamsFromQueryBuilderState(queryBuilderState),
      });

      const translatedQueryString = translateVariablesToDateTimeString(queryString);

      const url = formQueryURL(baseEndpoint, baseParams, translatedQueryString);

      fetchQueryData(url, queryBuilderState.entity.value, metadata);

      if (triggerTour) {
        tourSteps['get-data'].next();
      }
    },
    [baseEndpoint, baseParams, fetchQueryData, metadata, systemType]
  );

  const { resetAllState } = reportViewStateHandlers;

  useEffect(() => {
    if (connectionId === params.connectionId) {
      tourStateActions.resume();
    }

    return () => resetAllState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionId, params.connectionId]);

  useEffect(() => {
    const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);

    if (hasPlanExpired) {
      handleError({
        type: CONNECTION_ERROR_TYPE.EXPIRED,
        connectionName: title,
        expiryDate: formatEdmDateTime({ dateTime: billingUsage?.last_date, skipTime: true }),
      });
    }
  }, [billingUsage?.last_date, handleError, title]);

  const isMetadataProcessing = isMetadataLoading || isMetadataParsing;

  if (isSchemaVisualizerOpen) {
    return (
      <SchemaVisualizer
        metadata={metadata}
        toggleSchemaVisualizer={toggleSchemaVisualizer}
        updateDiagramInstance={updateDiagramInstance}
      />
    );
  }

  return (
    <>
      <Grid container>
        <Grid item>
          {!isMetadataProcessing && (
            <Tooltip title={isDrawerOpen ? 'Close Drawer' : 'Open Drawer'} placement="bottom">
              <CustomFab
                size="small"
                color="primary"
                className={clsx(classes.floatingActionButton, {
                  [classes.panelOpen]: isDrawerOpen,
                })}
                onClick={toggleDrawer}
                data-tour-step={tourSteps['side-panel-btn'].id}
              >
                <BsReverseLayoutSidebarInsetReverse size="18px" />
              </CustomFab>
            </Tooltip>
          )}
        </Grid>
        {isDrawerOpen && (
          <Grid item>
            <SidePanel
              isDrawerOpen={isDrawerOpen}
              populateQueryBuilder={populateQueryBuilder}
              openCreateBookmarkDialog={openCreateBookmarkDialog}
              baseEndpoint={baseEndpoint}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent={isMetadataProcessing ? 'center' : 'flex-start'}
        alignItems={isMetadataProcessing ? 'center' : 'flex-start'}
        className={classes.container}
      >
        {!error && metadata && (
          <QueryUrlProvider baseEndpoint={baseEndpoint} baseParams={baseParams}>
            <Grid item className={classes.formContainer}>
              <Grid item container spacing={2}>
                <Grid item xs={2} />
                <Grid item container xs={10} alignItems="center" spacing={1}>
                  <Grid item container justifyContent="space-between" xs={9}>
                    <Grid
                      item
                      data-tour-step={tourSteps['query-builder'].id}
                      className={classes.expandPanel}
                    >
                      <Typography variant="h6" align="center">
                        <IconButton
                          color="primary"
                          size="small"
                          className={classes.queryExpand}
                          onClick={toggleQueryBuilderExpand}
                        >
                          {isQueryBuilderExpanded ? <MdExpandMore /> : <MdChevronRight />}
                        </IconButton>
                        Data Explorer
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={isQueryBuilderExpanded}>
                <QueryBuilder
                  schema={metadata}
                  fetching={queryData.loading}
                  baseEndpoint={baseEndpoint}
                  baseParams={baseParams}
                  systemType={systemType}
                  fetchQueriedData={fetchQueriedData}
                />
              </Collapse>
            </Grid>

            <QueryDisplay
              systemType={systemType}
              schema={metadata}
              baseEndpoint={baseEndpoint}
              baseParams={baseParams}
              openCreateBookmarkDialog={openCreateBookmarkDialog}
              importAdhocQuery={importAdhocQuery}
            />

            <Grid item container>
              <DataTable
                key={queryData?.value?.key || 'default'}
                connectionName={title ?? ''}
                queryData={queryData}
                schema={metadata}
                fetchQueriedData={fetchQueriedData}
              />
            </Grid>
          </QueryUrlProvider>
        )}
      </Grid>
      {bookmarkDialog.isOpen && (
        <CreateBookmarkDialog
          isOpen={bookmarkDialog.isOpen}
          handleClose={closeCreateBookmarkDialog}
          queryUrl={bookmarkDialog.queryUrl}
          connectionId={params.connectionId}
        />
      )}
      <AbortBulkExportDialog
        dialogInfo={showAbortExportDialog}
        handleClose={closeAbortExportDialog}
      />
    </>
  );
}

const useHeaderStyles = makeStyles((theme) => ({
  titleBar: {
    height: theme.spacing(6),
    top: theme.spacing(5.5),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    borderBottom: theme.borders[0],
    padding: theme.spacing(0, 0.8),
  },
  projectDetails: {
    marginLeft: theme.spacing(1.5),
  },
  button: {
    height: theme.spacing(4),
    marginLeft: theme.spacing(1),
    minWidth: theme.spacing(17),
  },
  bulkExportErrorButton: {
    backgroundColor: theme.palette.error.main,
    animation: '$pulse 2s infinite',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  '@keyframes pulse': {
    '0%': { boxShadow: `0 0 0 0 rgba(253, 89, 94, 0.7)` },
    '70%': { boxShadow: `0 0 0 10px rgba(253, 89, 94, 0)` },
    '100%': { boxShadow: `0 0 0 0 rgba(253, 89, 94, 0)` },
  },
}));

const BulkDataExportButtonIcon = ({ exportStage, downloadProgress }) => {
  if (exportStage === BULK_EXPORT_STAGES.ERROR) {
    return <MdError />;
  } else {
    if (downloadProgress) {
      return downloadProgress;
    }

    return <CircularProgress size={15} />;
  }
};

function AbortBulkExportDialog({ dialogInfo, handleClose }) {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  const { showDialog, navigateTo } = dialogInfo;

  const handleAbort = useCallback(() => {
    bulkExportStateActions.abortDownload();
    bulkExportStateActions.reset();
    handleClose();
    if (navigateTo === 'defaultView') {
      goBackToPreviousPage(history, location);
      return;
    }
  }, [handleClose, history, location, navigateTo]);

  return (
    <>
      <Dialog open={showDialog} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Bulk Export in progress</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Moving away from the screen will abort the ongoing data export. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disableElevation
            onClick={handleClose}
            autoFocus
            size="small"
            color="primary"
          >
            No
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={handleAbort}
            size="small"
            className={classes.abortBtn}
          >
            Yes, Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const BackButton = ({
  isSchemaVisualizerOpen,
  isQueryViewOpen,
  toggleSchemaVisualizer,
  setIsQueryViewOpen,
  isBulkExportActive,
  setShowAbortExportDialog,
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleQueryViewBackNav = useCallback(() => {
    if (isBulkExportActive) {
      setShowAbortExportDialog({ showDialog: true, navigateTo: 'defaultView' });
      return;
    }
    setIsQueryViewOpen(false);
    goBackToPreviousPage(history, location);
  }, [history, isBulkExportActive, location, setIsQueryViewOpen, setShowAbortExportDialog]);

  const handleReportViewBackNav = useCallback(() => {
    goBackToPreviousPage(history, location);
  }, [history, location]);

  if (isSchemaVisualizerOpen) {
    return (
      <Grid item>
        <IconButton onClick={toggleSchemaVisualizer} size="small" color="primary">
          <IoIosArrowBack size="23px" />
        </IconButton>
      </Grid>
    );
  }

  if (isQueryViewOpen) {
    return (
      <Grid item>
        <IconButton onClick={handleQueryViewBackNav} size="small" color="primary">
          <IoIosArrowBack size="23px" />
        </IconButton>
      </Grid>
    );
  }

  return (
    <Grid item>
      <IconButton onClick={handleReportViewBackNav} size="small" color="primary">
        <IoIosArrowBack size="23px" />
      </IconButton>
    </Grid>
  );
};

export function ConnectionHeader({
  title,
  isSchemaVisualizerOpen,
  toggleSchemaVisualizer,
  metadata,
  systemType,
  handleExport,
  setShowAbortExportDialog,
  baseEndpoint,
}) {
  const history = useHistory();

  const [isQueryViewOpen, setIsQueryViewOpen] = useState(false);
  const location = useLocation();
  const urlContainsQueryView = location.pathname.includes('queryView');
  useEffect(() => {
    if (urlContainsQueryView) {
      setIsQueryViewOpen(true);
      return;
    }
    setIsQueryViewOpen(false);
  }, [urlContainsQueryView]);

  const classes = useHeaderStyles();

  const params = useParams();
  const { connectionId } = params;

  const { connections, isConnectionsLoading } = useConnectionsAndSystems();
  const selectedConnection = useMemo(
    () =>
      !isConnectionsLoading
        ? connections.find((conn) => conn.connection_id === connectionId)
        : null,
    [connections, connectionId, isConnectionsLoading]
  );
  const { user_system_id } = selectedConnection ?? {};

  const { nodeDataArray } = useSchemaVisualizerState();

  const { isFeatureQuotaExhausted } = useBillingUsage(FEATURE_CODES.REPORT_VIEW);

  const { url } = useRouteMatch();

  const tenant = useTenantState();
  const {
    isActive: isBulkExportActive,
    exportStage,
    totalBatches,
    activeBatch,
    isMinimized,
  } = useBulkExportState();
  const downloadProgress =
    exportStage === BULK_EXPORT_STAGES.IN_PROGRESS
      ? `${calculateDownloadProgress(activeBatch, totalBatches, exportStage)}%`
      : null;

  const openReportView = useCallback(() => {
    if (isBulkExportActive) {
      setShowAbortExportDialog({ showDialog: true, navigateTo: 'queryView' });
      return;
    }
    goBackToPreviousPage(history, location);
  }, [history, isBulkExportActive, location, setShowAbortExportDialog]);

  const openQueryView = useCallback(() => {
    history.push(`${url}/queryView`);
  }, [history, url]);

  const VisualizeModeButton = () => {
    // If metadata exists, schema visualizer is not open and bulk export is not active then render Visualize Mode Button
    if (metadata && !isSchemaVisualizerOpen) {
      if (isBulkExportActive && isMinimized) {
        return (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disableElevation
            className={clsx(classes.button, {
              [classes.bulkExportErrorButton]: exportStage === BULK_EXPORT_STAGES.ERROR,
            })}
            onClick={bulkExportStateActions.maximizeDialog}
            startIcon={
              <BulkDataExportButtonIcon
                exportStage={exportStage}
                downloadProgress={downloadProgress}
              />
            }
          >
            {exportStage === BULK_EXPORT_STAGES.ERROR ? 'Download Failed' : 'Download in Progress'}
          </Button>
        );
      } else {
        if (!exportStage) {
          return (
            <>
              {!isQueryViewOpen && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  className={classes.button}
                  onClick={toggleSchemaVisualizer}
                  startIcon={<RiBarChartBoxLine />}
                >
                  Visualize Mode
                </Button>
              )}
              {isQueryViewOpen && (
                <Button
                  color="primary"
                  className={classes.button}
                  size="small"
                  variant="contained"
                  disableElevation
                  startIcon={<MdGridOn />}
                  disabled={isFeatureQuotaExhausted}
                  onClick={openReportView}
                >
                  Report View
                </Button>
              )}
            </>
          );
        }
      }
    } else if (metadata && isSchemaVisualizerOpen && handleExport) {
      return (
        <Button
          variant="outlined"
          color="default"
          size="small"
          disableElevation
          className={classes.button}
          onClick={handleExport}
          disabled={nodeDataArray.length < 1}
          startIcon={<MdFileDownload />}
        >
          Export Schema Diagram
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.titleBar}
      >
        <Grid item>
          <Grid container>
            <BackButton
              tenant={tenant}
              isSchemaVisualizerOpen={isSchemaVisualizerOpen}
              toggleSchemaVisualizer={toggleSchemaVisualizer}
              isQueryViewOpen={isQueryViewOpen}
              setIsQueryViewOpen={setIsQueryViewOpen}
              isBulkExportActive={isBulkExportActive}
              setShowAbortExportDialog={setShowAbortExportDialog}
            />
            <Grid
              item
              className={classes.projectDetails}
              component={Typography}
              variant="h6"
              align="center"
            >
              {title}
            </Grid>
            <Grid item style={{ marginLeft: '5px' }}>
              <Tooltip title="Open System">
                <IconButton
                  size="small"
                  onClick={() => navigateToSystems(tenant?.tenant_id, user_system_id)}
                >
                  <MdSettings size="20px" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={6} justifyContent="flex-end">
          {metadata && isQueryViewOpen && !isSchemaVisualizerOpen && (
            <Grid item>
              <AIQueryButton
                baseEndpoint={baseEndpoint}
                schema={metadata}
                systemType={systemType}
              />
            </Grid>
          )}
          <Grid item>
            <VisualizeModeButton />
          </Grid>
          {metadata && !isQueryViewOpen && !isSchemaVisualizerOpen && (
            <Tooltip title={isFeatureQuotaExhausted ? 'You cannot access Query View' : ''}>
              <span>
                <Grid item>
                  <Button
                    color="primary"
                    className={classes.button}
                    size="small"
                    variant="contained"
                    disableElevation
                    startIcon={<BiTable style={{ fontSize: 16 }} />}
                    onClick={openQueryView}
                  >
                    QueryView
                  </Button>
                </Grid>
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {metadata && <ExpiryBanner />}
    </>
  );
}

export function Connection() {
  useInitialLoadNormal();

  const { connectionId } = useParams();
  const { selectedConnection: connection, isConnectionsLoading } = useConnection(connectionId);

  if (isConnectionsLoading) {
    return (
      <>
        <Nav />
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <Grid item>
            <ConnectionLoader isConnectionLoading={true} />
          </Grid>
        </Grid>
      </>
    );
  }

  if (isEmpty(connection)) {
    return (
      <>
        <Nav />
        <UnauthorisedAccessFallback />
      </>
    );
  }

  if (!connection.is_active) {
    return (
      <>
        <Nav />
        <InactiveConnectionFallback connectionId={connection?.connection_id} />
      </>
    );
  }

  return (
    <>
      <Nav />
      <ErrorBoundary resetKeys={[connectionId]} FallbackComponent={ConnectionPageFallback}>
        <ConnectionRouter connection={connection} />
      </ErrorBoundary>
    </>
  );
}

function ConnectionRouter({ connection }) {
  let { path } = useRouteMatch();

  const {
    metadata,
    connectionName: title,
    error,
    baseEndpoint,
    baseParams,
    isMetadataLoading,
    isMetadataParsing,
    finalPingStatus,
    isPingingSystem,
    isMetadataFetcherIdle,
    pingTestErrorMessage,
    missingValues,
    isSystemSecretsFetching,
    showLoader,
  } = useODataConnection(connection);

  const { isOpen: isSchemaVisualizerOpen, toggle: toggleSchemaVisualizer } = useDisclosure();
  const { handleExport, updateDiagramInstance } = useExportImage(title);
  const [showAbortExportDialog, setShowAbortExportDialog] = useState({
    showDialog: false,
    navigateTo: null,
  });

  const headerProps = {
    title,
    isSchemaVisualizerOpen,
    toggleSchemaVisualizer,
    metadata,
    handleExport,
    setShowAbortExportDialog,
  };

  const user = useUser();
  const userId = user?.user_id;

  const isMetadataProcessing = (isMetadataLoading || isMetadataParsing) && !isMetadataFetcherIdle;

  if (!isSystemSecretsFetching && missingValues.length > 0) {
    return <MetadataFetchFallback missingValues={missingValues} title={'Missing system secrets'} />;
  }

  if (connection.is_archived) {
    return <ArchivedConnectionFallback connectionId={connection?.connection_id} />;
  }

  const usersAssignedToSystem = connection.user_system?.assignedTo ?? [];
  const userHasAccessToSystem = usersAssignedToSystem.some((user) => user.user_id === userId);

  // If the user does not have access to the system, show the unauthorized screen
  if (!userHasAccessToSystem) {
    return <UnauthorisedAccessFallback />;
  }

  if (isPingingSystem) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Grid item>
          <ConnectionLoader isPingingSystem={true} />
        </Grid>
      </Grid>
    );
  }

  // If ping test throws an error message, we show the below fallback
  if (pingTestErrorMessage) {
    return <PingTestError errorMessage={pingTestErrorMessage} connection={connection} />;
  }

  if (isMetadataProcessing) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Grid item>
          <ConnectionLoader
            isMetadataLoading={isMetadataLoading}
            isMetadataParsing={isMetadataParsing}
          />
        </Grid>
      </Grid>
    );
  }

  const isOAuthConnection = connection.user_system?.connection_type === CONNECTION_TYPES.OAUTH.KEY;

  if (isOAuthConnection && finalPingStatus === PING_TEST.FAILURE) {
    return <UnauthorisedAccessFallback isOAuthAccessError={true} />;
  }

  return (
    !showLoader && (
      <>
        <ConnectionHeader {...headerProps} />
        <Switch>
          <Route exact path={path}>
            <ThemeProvider theme={mui5Theme}>
              <ScopedCssBaseline sx={{ height: '100vh' }}>
                <ReportView
                  schema={metadata}
                  baseEndpoint={baseEndpoint}
                  baseParams={baseParams}
                  connectionName={title ?? ''}
                  isMetadataLoading={isMetadataLoading}
                  isMetadataParsing={isMetadataParsing}
                  isSchemaVisualizerOpen={isSchemaVisualizerOpen}
                  toggleSchemaVisualizer={toggleSchemaVisualizer}
                  updateDiagramInstance={updateDiagramInstance}
                />
              </ScopedCssBaseline>
            </ThemeProvider>
          </Route>
          <Route path={`${path}/queryView`}>
            <ConnectionPage
              metadata={metadata}
              baseEndpoint={baseEndpoint}
              baseParams={baseParams}
              title={title}
              isMetadataLoading={isMetadataLoading}
              isMetadataParsing={isMetadataParsing}
              isSchemaVisualizerOpen={isSchemaVisualizerOpen}
              toggleSchemaVisualizer={toggleSchemaVisualizer}
              updateDiagramInstance={updateDiagramInstance}
              error={error}
              showAbortExportDialog={showAbortExportDialog}
              setShowAbortExportDialog={setShowAbortExportDialog}
            />
          </Route>
          <Route path="*">
            <PageNotFoundFallback connectionId={connection?.connection_id} />
          </Route>
        </Switch>
      </>
    )
  );
}

const useFallbackStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    margin: theme.spacing(10, 0, 0),
    paddingTop: theme.spacing(4),
  },
  actionButton: {
    margin: theme.spacing(2, 0.5, 0),
  },
  errorDetails: {
    width: theme.spacing(120),
    maxHeight: theme.spacing(15),
    padding: 0,
  },
  alertMessage: {
    overflowY: 'auto',
    padding: theme.spacing(2, 0),
  },
  link: {
    color: '#4493F8',
  },
  alertIcon: {
    margin: theme.spacing(1, 1.5),
  },
  primaryErrorMsg: {
    marginTop: theme.spacing(5),
  },
}));

function MetadataFetchFallback({ type, missingValues, title, errorDetails, additionalInfo }) {
  const classes = useFallbackStyles();
  const { connectionId } = useParams();
  const { selectedConnection: connection } = useConnection(connectionId);

  const [isErrorDetailsExpanded, setIsErrorDetailsExpanded] = useState(true);
  const toggleErrorDetails = () => {
    setIsErrorDetailsExpanded((state) => !state);
  };

  const navigateToSystemConfiguration = useCallback(() => {
    navigateToSystems(connection?.tenant_id, connection?.user_system_id);
  }, [connection]);

  if (type === API_ERROR_TYPES.UNAUTHORIZED) {
    return <UnauthorisedAccessFallback />;
  }

  return (
    <>
      <ConnectionHeader title={title} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <ErrorImage height={224} width={330} />
        <br />
        <Typography variant="h5" component="p" align="center" color="error" gutterBottom>
          Unable to connect to this system
        </Typography>
        <br />
        <Typography variant="h6" component="p" align="center" color="textSecondary" gutterBottom>
          Please check the secrets configured for the system. We currently only support{' '}
          <strong>OData Version 2</strong> as of now.
        </Typography>
        {additionalInfo && (
          <Alert variant="outlined" severity="error">
            {additionalInfo}
          </Alert>
        )}
        {missingValues && missingValues.length > 0 && (
          <>
            <Typography
              variant="h6"
              component="p"
              align="center"
              color="textSecondary"
              gutterBottom
            >
              The following system configuration values seem to be missing -
            </Typography>
            <Typography variant="overline" component="p" align="center" gutterBottom>
              {missingValues.join(', ')}
            </Typography>
          </>
        )}
        <br />
        {!isEmpty(errorDetails) && (
          <>
            <Collapse in={isErrorDetailsExpanded}>
              <Alert
                classes={{ message: classes.alertMessage, icon: classes.alertIcon }}
                className={classes.errorDetails}
                severity="error"
              >
                {errorDetails}
              </Alert>
            </Collapse>
            <br />
          </>
        )}
        <Grid item container justifyContent="center">
          {!isEmpty(errorDetails) && (
            <Button
              onClick={toggleErrorDetails}
              color="primary"
              disableElevation
              className={classes.actionButton}
              disabled={!errorDetails}
            >
              {isErrorDetailsExpanded ? 'Hide Details' : 'Show Details'}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={navigateToSystemConfiguration}
            className={classes.actionButton}
          >
            Configure Secrets
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

function MetadataParseFallback({ title }) {
  const classes = useFallbackStyles();

  const { connectionId } = useParams();
  const { selectedConnection: connection } = useConnection(connectionId);

  const navigateToSystemConfiguration = useCallback(() => {
    navigateToSystems(connection?.tenant_id, connection?.user_system_id);
  }, [connection]);

  return (
    <>
      <ConnectionHeader title={title} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <ErrorImage height={224} width={330} />
        <br />
        <Typography variant="h5" component="p" color="error" align="center">
          Failed to parse the metadata!
        </Typography>
        <br />
        <Typography variant="h6" component="p" align="center" color="textSecondary" gutterBottom>
          Please check that the metadata is in valid form. We currently only support{' '}
          <strong>OData Version 2</strong> as of now.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={navigateToSystemConfiguration}
          className={classes.actionButton}
        >
          Configure Secrets
        </Button>
      </Grid>
    </>
  );
}

function RuntimeErrorFallback() {
  const classes = useFallbackStyles();

  const tenant = useTenantState();

  return (
    <>
      <ConnectionHeader title="Error" />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <ErrorImage height={224} width={330} />
        <Typography variant="h5" component="p" color="error" align="center" gutterBottom>
          Something went wrong!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          component={RouterLink}
          to={tenant ? `/t/${tenant.tenant_id}` : '/'}
          className={classes.actionButton}
        >
          Go back to Dataflow Home
        </Button>
      </Grid>
    </>
  );
}

function PageNotFoundFallback({ connectionId }) {
  const classes = useFallbackStyles();

  const tenant = useTenantState();

  return (
    <>
      <ConnectionHeader title="Error" />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
        spacing={4}
      >
        <Grid item>
          <PageNotFound height={224} width={330} />
        </Grid>

        <Typography variant="h5" component="p" color="error" align="center" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="h6" component="p" align="center" color="textSecondary" gutterBottom>
          The requested page isn't available. Please check your URL or go to the connection page
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          component={RouterLink}
          to={tenant ? `/t/${tenant.tenant_id}/connection/${connectionId}` : '/'}
          className={classes.actionButton}
        >
          Go to Connection
        </Button>
      </Grid>
    </>
  );
}

function BillingExpiryFallback({ title, expiryDate }) {
  const classes = useFallbackStyles();

  const tenant = useTenantState();
  const { user } = useLoggedInUser();

  return (
    <>
      <ConnectionHeader title={title} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <ErrorImage height={224} width={330} />
        <br />
        <Typography variant="h5" component="p" color="error" align="center">
          Your plan has expired!
        </Typography>
        <br />
        <Typography variant="h6" component="p" align="center" color="textSecondary" gutterBottom>
          Your usage plan expired on <strong>{expiryDate}</strong>. Please upgrade your usage plan
          to continue using the application.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          component={RouterLink}
          to={tenant ? `/t/${tenant.tenant_id}` : '/'}
          className={classes.actionButton}
        >
          Go back to Dataflow Home
        </Button>
        <br />
        <br />
        <br />
        <Alert variant="outlined" severity="info">
          <Typography variant="body1">
            To upgrade your plan reach out to us at
            <Link
              href={getMailToString({
                emailSubject: EMAIL_FORMATS.UPGRADE.subject,
                emailBody: EMAIL_FORMATS.UPGRADE.getBody(user, tenant),
              })}
            >
              <strong> {CONFIG.SUPPORT_EMAIL}</strong>
            </Link>
          </Typography>
        </Alert>
      </Grid>
    </>
  );
}

function ConnectionPageFallback({ error }) {
  useEffect(() => {
    if (error && CONFIG.ENV === 'production') {
      const scope = new Sentry.Scope();
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureException(error, scope);
    }
  }, [error]);

  if (error.type === CONNECTION_ERROR_TYPE.EXPIRED) {
    return <BillingExpiryFallback title={error.connectionName} expiryDate={error.expiryDate} />;
  }

  if (error.type === CONNECTION_ERROR_TYPE.FETCH) {
    return (
      <MetadataFetchFallback
        type={error.error?.type}
        missingValues={error.missingValues}
        errorDetails={error.error?.errorDetails}
        title={error.connectionName}
        additionalInfo={error.additionalInfo}
      />
    );
  } else if (error.type === CONNECTION_ERROR_TYPE.PARSE) {
    return <MetadataParseFallback title={error.connectionName} />;
  }

  return <RuntimeErrorFallback />;
}

function ArchivedConnectionFallback({ connectionId }) {
  const classes = useFallbackStyles();

  const { updateArchiveState, isArchiving } = useConnectionHelper(connectionId);

  return (
    <>
      <ConnectionHeader title="Archived Connection" />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <ArchivedConnectionImage height={224} width={330} margin={10} />
        <Typography
          variant="h5"
          component="p"
          color="textPrimary"
          align="center"
          gutterBottom
          className={classes.primaryErrorMsg}
        >
          You cannot access archived connections
        </Typography>
        <Typography variant="h5" component="p" color="textSecondary" align="center" gutterBottom>
          Unarchive the connection and please try opening this connection again.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => updateArchiveState(false)}
          className={classes.actionButton}
          startIcon={isArchiving ? <CircularProgress color="inherit" size={16} /> : <BsArchive />}
        >
          Unarchive connection
        </Button>
      </Grid>
    </>
  );
}

export function UnauthorisedAccessFallback({ isOAuthAccessError = false }) {
  const tenant = useTenantState();

  const classes = useFallbackStyles();

  return (
    <>
      <ConnectionHeader title={'Unauthorised Access'} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <AccessDeniedImage height={224} width={330} />
        <br />
        <Typography variant="h5" component="p" color="error" align="center">
          User does not have access to the Connection
        </Typography>
        <br />
        <Typography variant="h6" component="p" align="center" color="textSecondary" gutterBottom>
          {isOAuthAccessError
            ? 'Looks like you do not have access to this OAuth connection. Kindly reach out to the admin and request access.'
            : 'Looks like you are not assigned to the system linked to the connection. Kindly reach out to the admin and request access.'}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          component={RouterLink}
          to={tenant ? `/t/${tenant.tenant_id}` : '/'}
          className={classes.actionButton}
        >
          Go back to Dataflow Home
        </Button>
      </Grid>
    </>
  );
}

export function InactiveConnectionFallback({ connectionId }) {
  const classes = useFallbackStyles();

  const { activateConnection, isActivating } = useConnectionHelper(connectionId);

  return (
    <>
      <ConnectionHeader title={'Inactive Connection'} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <AccessDeniedImage height={224} width={330} />
        <br />
        <Typography variant="h5" component="p" color="error" align="center">
          Inactive connection
        </Typography>
        <br />
        <Typography variant="h6" component="p" align="center" color="textSecondary" gutterBottom>
          The connection is not active, please activate this connection to proceed.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.actionButton}
          onClick={activateConnection}
          startIcon={isActivating ? <CircularProgress color="inherit" size={16} /> : <CgBolt />}
        >
          Activate Connection
        </Button>
      </Grid>
    </>
  );
}

export function PingTestError({ errorMessage, connection }) {
  const classes = useFallbackStyles();

  const navigateToSystemConfiguration = useCallback(() => {
    navigateToSystems(connection?.tenant_id, connection?.user_system_id);
  }, [connection]);

  return (
    <>
      <ConnectionHeader title={'Invalid Credentials'} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <AccessDeniedImage height={224} width={330} />
        <br />
        <Typography variant="h5" component="p" color="error" align="center">
          Failed to test connectivity to the system
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          align="center"
          className={classes.alertMessage}
        >
          Please refer to the troubleshooting{' '}
          <Link
            href={getHelpPageLink(HELP_PAGES.OAUTH_TROUBLESHOOTING_GUIDE)}
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            className={classes.link}
          >
            guide
          </Link>{' '}
          and try again.
        </Typography>
        <br />
        <Typography variant="h6" component="p" align="center" color="textSecondary" gutterBottom>
          {errorMessage}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={navigateToSystemConfiguration}
          className={classes.actionButton}
        >
          Configure Secrets
        </Button>
      </Grid>
    </>
  );
}
